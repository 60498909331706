import { Box } from 'reflexbox/styled-components';

import { isEmptyString } from 'utils';

import { PdfPreviewError } from './pdf-preview-error';
import { PdfPreviewSkeleton } from './pdf-preview-skeleton';

type Props = {
    url?: string;
    loading?: boolean;
    error?: Error;
    uploadDocument?: () => void;
    minHeight?: string;
};

export const PDFPreview = ({
    url,
    loading = false,
    error,
    minHeight = '750px',
}: Props) => (
    <>
        {error && <PdfPreviewError error={error} />}
        {(isEmptyString(url) || loading) && !error && <PdfPreviewSkeleton />}
        {!isEmptyString(url) && !loading && !error && (
            <Box
                as="object"
                //@ts-expect-error Box does not support the `<object>` `type` property
                type="application/pdf"
                trusted="yes"
                application="yes"
                data={`${url}#view=FitH&scrollbar=1&toolbar=1&navpanes=1`}
                css={{
                    width: '100%',
                    height: '100%',
                    minHeight,
                }}
            />
        )}
    </>
);
